import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import ExternalLink from '../../../components/external-link'
import ActiveLink from '../../../components/active-link'
import Hero from '../../../components/hero'

const metaTags = {
  description: 'Learn about the insertion procedure and how to care for your child post-procedure.',
  keywords: 'Homepage',
  title: 'Insertion & After-care | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>What to expect with SUPPRELIN<sup>&reg;</sup> LA</h1>
        <h2>One full year of medication in one implant </h2>
        <p>One implant provides 1 year of continuous hormone medicine, histrelin acetate. This treats your child's central precocious puberty (CPP) for a full year after the first month of therapy.</p>
        <h2>Insertion procedure</h2>
        <ul className='brand-list'>
            <li>SUPPRELIN<sup>&reg;</sup> LA is placed under the skin of the inside of the upper arm </li>
            <li>The entire procedure can be done in a doctor’s office using a local anesthetic. Your child’s healthcare professional will recommend the appropriate setting</li>
            <li>A healthcare professional will temporarily numb your child’s upper arm, make a small cut, and insert the implant just under the skin </li>
            <li>A healthcare professional will then close the cut with stitches or surgical strips and cover with a bandage</li>
        </ul>
        <h2>After-care</h2>
        <h2>Follow these instructions after the procedure. Always remember to follow the advice of your child’s healthcare professional: </h2>
        <ul className='brand-list'>
            <li>Your child should keep the arm clean and dry and should not swim or bathe for 24 hours</li>
            <li>The bandage can be removed after 24 hours </li>
            <li><strong>Do not</strong> remove the surgical strips. They will fall off on their own in a few days </li>
            <li>Your child should avoid heavy play or exercise that uses the implanted arm for 7 days. After the cut has healed, your child can go back to his or her normal activities. The doctor will give you complete instructions</li>
            <li>Some people who had SUPPRELIN<sup>&reg;</sup> LA placed in their arm have had the implant come through the skin (extrusion). <strong>Call your child’s doctor right away</strong> if the SUPPRELIN<sup>&reg;</sup> LA implant comes through the skin</li>
        </ul>
        <h2>Follow-up visits are important</h2>
        <p>Your child must have regular visits with his or her pediatric endocrinologist. Your child may need blood tests a month after the procedure and every 6 months after that. A healthcare professional will also check for signs of puberty, measure height, and may take wrist X-rays to track bone growth.</p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix('/pdfs/SP-05697_Appointment_Reminder_Card_R1_Digital.pdf')}
          LinkCaption="Download appointment reminder tool to help manage your child’s appointments - insertion and after care"
        >
              Download appointment reminder tool to help manage your child’s appointments
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
